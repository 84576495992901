<template>
  <b-modal
    v-model="conditionalfacility"
    centered
    size="lg"
    title="Facility"
    ok-title="Ok"
    ok-only
    hide-footer
    no-close-on-backdrop
    @hide="onHide"
  >
    <div>
      <div>
        <iframe
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          :src="
            'https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=' +
            facilityItem +
            '&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
          "
          referrerpolicy="no-referrer-when-downgrade"
        >
        </iframe>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
/* eslint-disable global-require */

export default {
  components: {
    BModal,
  },
  props: {
    facilityItem: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      conditionalfacility: true,
    };
  },
  methods: {
    onHide() {
      this.$emit("cerrarModalFacility", false);
    },
    
  },
};
</script>
<style lang="scss" scoped>
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>